
import { defineComponent, ref, reactive } from "vue";
import store from "@/store";
import {
  existUser,
  update,
  getProfileByUsersId,
  uploadImage,
} from "@/components/firebaseOperations";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import EditProfileIconDialog from "@/components/parts/EditProfileIconDialog.vue";
import DialogBase from "@/components/parts/DialogBase.vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import { removeImageValue } from "@/components/imageFunc";
import constants from "@/components/constants";
import { getSns } from "@/components/aboutArticleFunc";
import { openModal, closeModal } from "@/components/modalFunc";
import { createSearchCandidates } from "@/components/createSearchCandidates";
import Compressor from "compressorjs";

export default defineComponent({
  components: {
    EditProfileIconDialog,
    VueCropper,
    DialogBase,
  },
  setup() {
    const router = useRouter();
    const cropper = ref<HTMLButtonElement>() as any;

    onBeforeRouteLeave((to, from, next) => {
      if (state.completed) {
        next();
      } else {
        state.transitionTo = to.path;
        state.discardModal = true;
        next(false);
      }
    });

    const state = reactive({
      imageCroppingModal: false,
      discardModal: false,
      user: Object.create(store.state.myUser),
      sns: [] as { [key: string]: string }[],
      errorMessage: {
        image: "",
        userName: "",
      },
      imgSrc: "",
      originalImg: {
        height: 0,
        width: 0,
      },
      completed: false,
      transitionTo: "",
    });

    state.sns = getSns(true, state.user);

    const fileUpload = async (e: any): Promise<void> => {
      const payload: Compressor.Options = {
        quality: 0.75,
        mimeType: "image/jpeg",
        async success(blob: Blob) {
          const reader = new FileReader();
          (reader.onloadend = async () => {
            const result = reader.result;
            if (result instanceof ArrayBuffer || result === null) return;

            state.imgSrc = result;
            const img = new Image();
            img.onload = async function() {
              state.originalImg.height = img.height;
              state.originalImg.width = img.width;
            };
            img.src = reader.result as string;
            cropper.value.replace(result); // 切り取り対象画像を設定
          }),
            reader.readAsDataURL(blob);
          state.errorMessage.image = "";
          openModal(state, constants.MODAL.IMAGE_CROPPING);
        },
        error(err: Error): void {
          console.log(err.message);
        },
      };
      const imageFile: File = e.target.files[0];
      new Compressor(imageFile, payload);
    };

    /// モーダルを閉じる
    const closeImageCroppingModal = () => {
      closeModal(state, constants.MODAL.IMAGE_CROPPING);
      cropper.value.reset(); // 切り取り対象として設定していた画像をリセット
    };

    /// 画像を切り取る
    const doCropImage = () => {
      state.user.image_url = cropper.value.getCroppedCanvas().toDataURL(); // 切り取った画像のurlを代入
      closeModal(state, constants.MODAL.IMAGE_CROPPING);
    };

    const discard = () => {
      state.completed = true;
      if (state.transitionTo.length != 0) {
        router.push({ path: state.transitionTo });
      } else {
        router.push({
          path: `/${state.user.name}`,
        });
      }
    };

    const openDiscardModal = () => {
      state.discardModal = true;
    };

    const cancel = () => {
      state.discardModal = false;
    };

    /// 編集完了
    const finish = async () => {
      const updateDoc: { [key: string]: any } = {
        updated_at: new Date(),
      };
      state.errorMessage.userName = "";

      if (state.user.name != "") {
        if (state.user.name.length <= 20) {
          if (store.state.myUser.name != state.user.name) {
            if (!(await existUser(state.user.name))) {
              updateDoc["name"] = state.user.name;
              updateDoc["search_candidates"] = createSearchCandidates(
                state.user.name
              );
            } else {
              state.errorMessage.userName = "既に使われています。";
            }
          }
        } else {
          state.errorMessage.userName = "20文字以内で入力してください。";
        }
      } else {
        state.errorMessage.userName = "何か入力して下さい。";
      }

      if (state.errorMessage.userName == "") {
        for (let sns of state.sns) {
          const columnName = sns.name + "_url";
          if (store.state.myUser[columnName] != sns.url) {
            updateDoc[columnName] = sns.url;
          }
        }

        if (store.state.myUser["image_url"] != state.user.image_url) {
          const uploadImageUrl = await uploadImage(
            state.user.image_url,
            "profileIcons",
            `${store.state.myUser.users_id}.jpeg`
          );

          updateDoc["image_url"] = uploadImageUrl;
        }

        if (state.user.first_profile_editing) {
          updateDoc["first_profile_editing"] = false;
        }

        if (Object.keys(updateDoc).length >= 1) {
          // 更新対象ドキュメントがあった場合
          await update(
            constants.FIREBASE_COLLECTION.PROFILES,
            state.user.docId,
            updateDoc
          );
          await getProfileByUsersId(store.state.myUser.users_id, true);
        }

        state.completed = true;

        router.push({
          path: `/${state.user.name}`,
        });
      }
    };

    return {
      removeImageValue,
      constants,
      state,
      cropper,
      store,
      fileUpload,
      finish,
      doCropImage,
      closeImageCroppingModal,
      cancel,
      discard,
      openDiscardModal,
      closeModal,
    };
  },
});
