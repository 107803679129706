import { resolveComponent as _resolveComponent, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "edit-profile" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_EditProfilePage = _resolveComponent("EditProfilePage")!
  const _component_BottomNavigation = _resolveComponent("BottomNavigation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        _createVNode(_component_EditProfilePage)
      ]),
      fallback: _withCtx(() => []),
      _: 1
    })),
    _createVNode(_component_BottomNavigation)
  ]))
}