<template>
  <transition name="modal" appear :scrollable='false'>
    <div class="modal modal-overlay">
      <div class="modal-window">
        <div class="modal-content">
          <slot/>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/globals.scss";

.modal {
  &.modal-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 10000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  &-window {
    background: #fff;
    border-radius: 20px;
    overflow-x: hidden;
    overflow-y: auto; // 縦スクロール可能にする;
    margin: 10px;
    max-height: 90%;
    width: 90%;

    @include mq-up() {
      max-height: 100%;
      width: 35em;
    }

    overflow-y: scroll;
    /* IE, Edge 対応 */
    -ms-overflow-style: none;
    /* Firefox 対応 */
    scrollbar-width: none;

    /* Chrome, Safari 対応 */
    &::-webkit-scrollbar {
      display:none;
    }
  }

  &-content {
    padding: 10px 10px;
  }
}

// オーバーレイのトランジション
.modal-enter-active, .modal-leave-active {
  transition: opacity 0.4s;

  // オーバーレイに包含されているモーダルウィンドウのトランジション
  .modal-window {
    transition: opacity 0.4s, transform 0.4s;
  }
}

// ディレイを付けるとモーダルウィンドウが消えた後にオーバーレイが消える
.modal-leave-active {
  transition: opacity 0.6s ease 0.4s;
}

.modal-enter, .modal-leave-to {
  opacity: 0;

  .modal-window {
    opacity: 0;
    transform: translateY(-20px);
  }
}
</style>